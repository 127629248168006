(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/category-menu/assets/javascripts/category-menu.js') >= 0) return;  svs.modules.push('/widgets/category-menu/assets/javascripts/category-menu.js');
"use strict";


const getIndexInWidgetArea = categoryMenu => {
  const closesWidgetArea = categoryMenu.closest('.widget-area');
  let presumedIndex = null;
  closesWidgetArea.childNodes.forEach((widget, index) => {
    var _widget$firstChild;
    if (widget !== null && widget !== void 0 && (_widget$firstChild = widget.firstChild) !== null && _widget$firstChild !== void 0 && _widget$firstChild.className && widget.firstChild.className.includes('js-category-menu-widget')) {
      presumedIndex = index + 1;
    }
  });
  return presumedIndex;
};

const addClickListenerForItems = (categoryMenu, widgetIndex) => {
  categoryMenu.querySelectorAll('.js-items-menu-item').forEach((element, index) => {
    element.addEventListener('click', e => {
      const navItemTitle = e.currentTarget.attributes.title.textContent;
      const evt = {
        ID: svs.utils.url.getLocation().pathname,
        name: "category-menu/".concat(index + 1, "/").concat(navItemTitle),
        position: widgetIndex
      };
      svs.components.analytics.trackPromoClick(evt);
    });
  });
};

const init = () => {
  const categoryMenus = document.querySelectorAll('.js-category-menu-widget');

  if (categoryMenus.length > 0) {
    const options = {
      useScrollFaders: true,
      el: 'js-category-menu',
      class: 'js-category-menu'
    };
    new svs.ui.ItemsMenu(options);
  }

  categoryMenus.forEach(categoryMenu => {
    const widgetIndex = getIndexInWidgetArea(categoryMenu);
    addClickListenerForItems(categoryMenu, widgetIndex);
  });

  for (let index = 0; index < categoryMenus.length; index++) {
    const currMenu = categoryMenus[index];
    const currWidgetTopInViewport = currMenu.parentNode.getBoundingClientRect().top;
    const allWidgetsAreaTopInViewport = currMenu.parentNode.parentNode.getBoundingClientRect().top;
    const isFirstVisibleWidget = currWidgetTopInViewport === allWidgetsAreaTopInViewport;
    currMenu.dataset.transform = isFirstVisibleWidget ? 'never' : 'desktop';
  }
  const categoryMenu = document.querySelector('.js-category-menu-widget');
  const navElement = categoryMenu.querySelector('.js-items-menu-nav');
  navElement.setAttribute('aria-label', 'Kategorimeny');
  navElement.querySelectorAll('.items-menu-icon-holder').forEach(item => {
    item.setAttribute('aria-hidden', 'true');
  });
};
init();

 })(window);